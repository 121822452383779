<template>
  <div>
    <Tabs :value="mIdx">
      <TabList>
        <Tab value=0>주문</Tab>
        <Tab value=1>비용</Tab>
        <Tab value=2>배송</Tab>
        <Tab value=3>문제</Tab>
        <Tab value=5 @click="click({type:'faq'})">꿀팁</Tab>
        <Tab value=4>기타</Tab>
      </TabList>
      <TabPanels class="card main">
        <TabPanel value=0>
          <Accordion :value="idx">
            <AccordionPanel value="0" >
              <AccordionHeader >구매대행</AccordionHeader>
              <AccordionContent>
                <faqc idx=0 />
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="1">
              <AccordionHeader class="datatable-background">배송대행</AccordionHeader>
              <AccordionContent>
                <faqc idx=1 />
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="2">
            <AccordionHeader>결제대행</AccordionHeader>
              <AccordionContent>
                <faqc idx=2 />
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="3">
              <AccordionHeader class="datatable-background">필수정보</AccordionHeader>
              <AccordionContent>
                <faqc idx=3 />
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="4">
              <AccordionHeader>원산지증명(FTA 통관)</AccordionHeader>
              <AccordionContent>
                <faqc idx=4 />
              </AccordionContent>
            </AccordionPanel>
          </Accordion>
        </TabPanel>
        <TabPanel value=1>
          <Accordion :value="idx">
            <AccordionPanel value="5">
              <AccordionHeader>예상견적</AccordionHeader>
              <AccordionContent>
                <faqc idx=5 />
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="6">
              <AccordionHeader class="datatable-background">세금</AccordionHeader>
              <AccordionContent>
                <faqc idx=6 />
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="7">
              <AccordionHeader>수수료</AccordionHeader>
              <AccordionContent>
                <faqc idx=7 />
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="8">
              <AccordionHeader class="datatable-background">추가비용</AccordionHeader>
              <AccordionContent>
                <faqc idx=8 />
              </AccordionContent>
            </AccordionPanel>
          </Accordion>
        </TabPanel>
        <TabPanel value=2>
          <Accordion :value="idx">
            <AccordionPanel value="9">
              <AccordionHeader>배송비</AccordionHeader>
              <AccordionContent>
                <faqc idx=9 />
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="10">
              <AccordionHeader class="datatable-background">배송기간</AccordionHeader>
              <AccordionContent>
                <faqc idx=10 />
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="11">
              <AccordionHeader>배송업체</AccordionHeader>
                <AccordionContent>
                  <faqc idx=11 />
                </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="12">
              <AccordionHeader class="datatable-background">진행상황</AccordionHeader>
              <AccordionContent>
                <faqc idx=12 />
              </AccordionContent>
            </AccordionPanel>
          </Accordion>
        </TabPanel>
        <TabPanel value=3>
          <Accordion :value="idx">
            <AccordionPanel value="13">
              <AccordionHeader>사기거래</AccordionHeader>
              <AccordionContent>
                <faqc idx=13 />
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="14">
              <AccordionHeader class="datatable-background">교환/환불/취소</AccordionHeader>
              <AccordionContent>
                <faqc idx=14 />
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="15">
              <AccordionHeader>세관통관</AccordionHeader>
              <AccordionContent>
                <faqc idx=15 />
              </AccordionContent>
            </AccordionPanel>
          </Accordion>
        </TabPanel>
        <TabPanel value="4">
          <Accordion :value="idx">
            <AccordionPanel value="16">
              <AccordionHeader>아이폰(홍콩)</AccordionHeader>
              <AccordionContent>
                <faqc idx=16 />
              </AccordionContent>
            </AccordionPanel>
            <AccordionPanel value="26">
              <AccordionHeader>문의</AccordionHeader>
              <AccordionContent>
                <faqc idx=26 />
              </AccordionContent>
            </AccordionPanel>
          </Accordion>
        </TabPanel>
        <TabPanel value="5" >
          <read page='faq'/>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </div>
</template>

<script>
import faqc from '../content/faq_content.vue'
import read from '@/Read.vue'

export default {
  mounted() {
    if(!(this.$route.query.idx == undefined || this.$route.query.idx === null || this.$route.query.idx === '')) {
      this.idx = this.$route.query.idx
      const tmpIdx = Number(this.idx)
      if(tmpIdx <= 4) this.mIdx = '0'
      else if(tmpIdx <= 8) this.mIdx = '1'
      else if(tmpIdx <= 12) this.mIdx = '2'
      else if(tmpIdx <= 15) this.mIdx = '3'
      else this.mIdx = '4'
    }
  },  
  components: {
    faqc, read
  },
  data() {
    return {
      idx:'-1',
      mIdx : '0',
    }
  },
  methods: {
    click(param) {
      const type = param.type
      if(type === 'faq') {
        this.emitter.emit('contentsTab')
      }
    }
  }
  
}

</script>
<style scoped>
.datatable-background {
  background-color:#f9f9f9;
}
</style>